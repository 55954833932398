import React from 'react'
import { Caption, Group, Token } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

import { PageWrapper } from '@src/components/Page/Page'
import { OnboardingChecklistHeader } from '../../OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageBody } from '@src/components/Page/PageBody'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { GoalsSettingsProps } from './Goals'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'

const Title = () => (
  <FormattedMessage id="goalsSettings.page.title" defaultMessage="Goals settings" />
)

const Description = () => (
  <FormattedMessage
    id="goalsSettings.page.description"
    defaultMessage="Setting for all goals in the system"
  />
)

export const GoalsSettingsAll = ({
  backUrl,
  targetTypesRoute,
  approvalProcessRoute,
  roadmapsRoute,
  templatesRoute,
  rulesRoute,
  isOnboarding,
  jiraIntegrationRoute,
}: GoalsSettingsProps) => {
  const permissions = useSelector(selectPermissions)
  const canViewJiraIntegration = permissions?.includes(
    PermissionTypes.ViewJiraIntegration,
  )
  return (
    <PageWrapper>
      {isOnboarding ? (
        <OnboardingChecklistHeader
          title={<Title />}
          description={<Description />}
          backUrl={pathToUrl(backUrl)}
        />
      ) : (
        <PageHeader
          title={<Title />}
          subtitle={<Description />}
          backUrl={pathToUrl(backUrl)}
        />
      )}

      <PageBody>
        <Group>
          <NewSelectionCard
            icon="Switches"
            title="Metric types"
            to={targetTypesRoute}
            use={InternalLink}
          />
          <NewSelectionCard
            icon="ViewGrid"
            title="Templates"
            to={templatesRoute}
            use={InternalLink}
          />
          <NewSelectionCard
            icon="IndustrialGear"
            title="Rules"
            to={rulesRoute}
            use={InternalLink}
          />
          <NewSelectionCard
            icon="DataLimit"
            title="Roadmaps"
            use={InternalLink}
            to={roadmapsRoute}
          />
          <NewSelectionCard
            icon="CheckVerification"
            title="Approval processes"
            subtitle="Create and manage custom approval processes for your business"
            to={approvalProcessRoute}
            use={InternalLink}
          />
        </Group>
        {canViewJiraIntegration && (
          <>
            <Caption color={Token.color.greyTone50} mt="s-24" mb="s-16">
              <FormattedMessage
                defaultMessage="Integrations"
                id="settings.goals.integrations.title"
              />
            </Caption>
            <Group>
              <NewSelectionCard
                icon="Jira|image"
                title="Jira"
                subtitle="Enable to import data from your Jira workspace"
                to={jiraIntegrationRoute}
                use={InternalLink}
              />
            </Group>
          </>
        )}
      </PageBody>
    </PageWrapper>
  )
}
