import { Group, ItemSkeleton, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { captureException } from '@sentry/react'

import { navigateTo } from '@src/actions/RouterActions'
import { createDraftGoal } from '@src/api/goals'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'

import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

import { pathToUrl } from '@src/utils/router'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

const Title = () => (
  <FormattedMessage id="goalsCreate.page.title" defaultMessage="Add new goal" />
)

const Description = () => (
  <FormattedMessage
    id="goalsCreate.page.description"
    defaultMessage="Select the goal level"
  />
)

export const CreateGoalLevelSelect = () => {
  const isOnboarding = isOnboardingPath()
  const featureFlags = useSelector(selectFeatureFlags)

  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const backUrl = isOnboarding
    ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL
    : ROUTES.PERFORMANCE.GOALS.GENERAL

  const [pending, setPending] = useState(false)
  const user = useSelector(selectUser)
  const statusPopup = useStatusPopup()

  const handleNew = async (entity: EntityTypes) => {
    setPending(true)

    const isCompany = [EntityTypes.companyV2, EntityTypes.company].includes(entity)

    try {
      const response = await createDraftGoal({
        owner: { id: user.id },
        is_company: isCompany,
      })
      navigateTo(
        pathToUrl(
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.CREATE.FORM
            : ROUTES.FORMS.GOAL.CREATE.FORM,
          { id: response.data.id },
          { type: entity },
        ),
        {
          isNew: true,
        },
      )
    } catch (err) {
      captureException(err)
      const description =
        err.response?.data?.detail || 'Something went wrong. Please try again.' // ToDo: agree on possible error from BE

      statusPopup.show(
        <StatusPopup
          variant="error"
          onClose={() => {
            navigateTo(backUrl)
          }}
        >
          <StatusPopup.Title>Failed to create goal</StatusPopup.Title>
          <StatusPopup.Description>{description}</StatusPopup.Description>
        </StatusPopup>,
      )
      setPending(false)
    }
  }

  return (
    <PageWrapper>
      {isOnboarding ? (
        <OnboardingChecklistHeader
          title={<Title />}
          description={<Description />}
          backUrl={pathToUrl(backUrl)}
        />
      ) : (
        <PageHeader
          title={<Title />}
          subtitle={<Description />}
          backUrl={pathToUrl(backUrl)}
        />
      )}

      <PageBody>
        <Group>
          {pending ? (
            <Skeleton />
          ) : (
            <>
              <NewSelectionCard
                /* @ts-expect-error CompanyFilled icon works but it's added to types in ui-kit v14 and there is a significant effort needed to upgade */
                icon={isCommercial ? 'CompanyFilled' : 'LogoRevolut'}
                title="Company goal"
                data-testid="company-goal-select"
                onClick={() => handleNew(EntityTypes.companyV2)}
              />
              <NewSelectionCard
                icon="Bank"
                title="Department goal"
                data-testid="department-goal-select"
                onClick={() => handleNew(EntityTypes.department)}
              />
              <NewSelectionCard
                icon="People"
                title="Team goal"
                data-testid="team-goal-select"
                onClick={() => handleNew(EntityTypes.teams)}
              />
            </>
          )}
        </Group>
      </PageBody>
    </PageWrapper>
  )
}

const Skeleton = () => {
  return (
    <>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </>
  )
}

const SkeletonItem = () => {
  return (
    <ItemSkeleton>
      <ItemSkeleton.Avatar />
      <ItemSkeleton.Content>
        <ItemSkeleton.Title />
      </ItemSkeleton.Content>
    </ItemSkeleton>
  )
}
