import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { MoreBar, VStack } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { bulkUpdateDocuments } from '@src/api/importData'
import { updateDocumentBulkSessionUploadInfo } from '@src/api/documents'
import { ImportDocumentsItemInterface } from '@src/interfaces/importDocuments'
import { useTable } from '@components/Table/hooks'
import {
  ImportInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import { PreviewDocSelectorBar } from '@src/pages/OnboardingChecklistV2/Documents/common/PreviewDocSelectorBar'
import { AttachedDocument } from '@src/features/DocumentSidebar/DocumentSidebar'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { DocumentUploadRequestInterface } from '@src/interfaces/documents'
import SideBar from '@src/components/SideBar/SideBar'
import { ChangeAssigneeAction } from './common/ChangeAssigneeAction'
import { bulkSessionRow } from './row'
import {
  getUploadSessionTable,
  getUploadSessionTableStats,
} from '@src/api/bulkDataImport'

export const DocumentsSession = () => {
  const params = useParams<{ id: string }>()
  const [previewTableItem, setPreviewTableItem] =
    useState<ImportDocumentsItemInterface | null>(null)

  const formatBulkData = (
    rowsData: { id: number }[] | undefined,
    field: 'category' | 'employee',
    optionId: string | number | undefined,
  ) =>
    rowsData
      ?.map(rowData =>
        typeof optionId === 'number'
          ? {
              id: rowData.id,
              [field]: { id: optionId },
            }
          : null,
      )
      .filter(Boolean) || []

  const table = useTable<
    ImportInterface<ImportDocumentsItemInterface>,
    ImportSessionStatsInterface
  >({
    getItems: getUploadSessionTable<ImportDocumentsItemInterface>(
      API.DOCUMENTS_UPLOADS_BULK,
      params.id,
    ),
    getStats: getUploadSessionTableStats(API.DOCUMENTS_UPLOADS_BULK, params.id),
  })
  // BE doesn't comply to standard import session data interface here, so we have to force by manual type casting
  const tableData = (table.data || []) as unknown as ImportDocumentsItemInterface[]

  return (
    <>
      <BulkDataUploadV2
        table={table}
        importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.FILES}
        sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
        anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.ANY}
        apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
        category="document_upload"
        name="document"
        tableName={TableNames.ImportDocumentsV2}
        row={bulkSessionRow}
        entity="document"
        templateParams={{ version: '2' }}
        onActionColumnPreview={rowData => {
          // BE doesn't comply to standard import session data interface here, so we have to force by manual type casting
          setPreviewTableItem(rowData as unknown as ImportDocumentsItemInterface)
        }}
        hiddenColumns={{ 'state.id': true }}
        getCustomEditCellAction={(data, rowId, sessionId) => {
          return updateDocumentBulkSessionUploadInfo(sessionId!, rowId, data)
        }}
        header={
          <PageHeader
            title="Upload documents"
            backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.LIST)}
          />
        }
        tableActions={tableActionsProps => (
          <MoreBar>
            <BulkEditAction
              buttonIcon="Suitcase"
              field="category"
              selector={selectorKeys.document_categories}
              {...tableActionsProps}
              getCustomApiCall={(sessionId, selectedRowsData, option) => {
                return () =>
                  bulkUpdateDocuments(
                    String(sessionId),
                    formatBulkData(selectedRowsData, 'category', option?.id),
                  )
              }}
            />
            <BulkEditAction
              buttonIcon="Profile"
              field="employee"
              label="assignee"
              selector={selectorKeys.all_employees_avatar_email}
              {...tableActionsProps}
              getCustomApiCall={(sessionId, selectedRowsData, option) => {
                return () =>
                  bulkUpdateDocuments(
                    String(sessionId),
                    formatBulkData(selectedRowsData, 'employee', option?.id),
                  )
              }}
            />
            <BulkDeleteButton {...tableActionsProps} />
          </MoreBar>
        )}
      />
      <SideBar
        title="Document preview"
        subtitle={
          previewTableItem?.employee ? (
            <UserWithAvatar {...previewTableItem.employee} />
          ) : (
            'Unassigned'
          )
        }
        isOpen={!!previewTableItem}
        onClose={() => {
          setPreviewTableItem(null)
        }}
        sideProps={{ resizable: true }}
      >
        {previewTableItem && (
          <AttachedDocument
            id={previewTableItem.id}
            genericFileInfo={{
              id: previewTableItem.file.id,
              name: previewTableItem.file.name,
            }}
            renderActions={(isLoading, downloadButton) => (
              <VStack space="s-16">
                <MoreBar>
                  {downloadButton}
                  <ChangeAssigneeAction<
                    ImportDocumentsItemInterface,
                    DocumentUploadRequestInterface
                  >
                    data={previewTableItem}
                    disabled={isLoading}
                    updateAction={(data, newAssignee) => {
                      return bulkUpdateDocuments(params.id, [
                        {
                          id: data.id,
                          employee: { id: newAssignee.id },
                        },
                      ])
                    }}
                    onSuccess={(_, newAssignee) => {
                      setPreviewTableItem({
                        ...previewTableItem,
                        employee: newAssignee || null,
                      })
                      table.refresh()
                    }}
                  />
                </MoreBar>
                <PreviewDocSelectorBar
                  items={tableData}
                  selectedItem={previewTableItem}
                  setSelectedItem={setPreviewTableItem}
                />
              </VStack>
            )}
          />
        )}
      </SideBar>
    </>
  )
}
