import React, { useState, useMemo } from 'react'
import {
  Box,
  Fixed,
  Flex,
  Group,
  Header,
  MoreBar,
  Side,
  Cell,
  Text,
  Image,
  Token,
  VStack,
  ProductWidgetSkeleton,
  StatusWidget,
} from '@revolut/ui-kit'
import { Document, Download } from '@revolut/icons'

import { useTheme } from '@src/styles/theme'
import { DocumentInterface } from '@src/interfaces/documents'
import { newDocumentRequests } from '@src/api/documents'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { SideBarHeaderTitle } from '@src/components/SideBar/SideBarHeaderTitle'
import { useGenericFileApi, useUploadedDocumentsApi } from './hooks'

interface AttachedDocumentProps<T> {
  id: number
  document?: T | null
  genericFileInfo?: { id: number; name: string }
  renderDetails?: () => React.ReactNode
  renderActions?: (
    isLoading: boolean,
    downloadButton?: React.ReactNode,
  ) => React.ReactNode
  pending?: boolean
}

export const AttachedDocument = <
  T extends { id: number; file_name: string; file_media_type: string },
>({
  id,
  document,
  genericFileInfo,
  renderDetails,
  renderActions,
  pending,
}: AttachedDocumentProps<T>) => {
  if (!pending && !document && !genericFileInfo) {
    throw new Error('File params are not specified')
  }
  const uploadedDocumentFileCtx = useUploadedDocumentsApi(id, document)
  const genericFileCtx = useGenericFileApi(id, genericFileInfo)

  const {
    isLoading = false,
    isError,
    canPreview,
    canSave,
    onSave,
    isPdf,
    isImage,
    fileUrl,
  } = uploadedDocumentFileCtx || genericFileCtx || {}

  const renderPreview = () => {
    if (pending || isLoading) {
      return (
        <Box mt="s-12">
          <ProductWidgetSkeleton />
        </Box>
      )
    }
    if (!fileUrl) {
      return (
        <VStack space="s-16">
          <Cell>
            <Flex
              flexDirection="column"
              alignItems="center"
              flex="1"
              py="s-32"
              gap="s-24"
            >
              {isError ? (
                <StatusWidget>
                  <StatusWidget.Image
                    image={{
                      default: 'https://assets.revolut.com/assets/3d-images-v2/3D358.png',
                      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D358@2x.png',
                      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D358@3x.png',
                    }}
                  />
                  <StatusWidget.Title>
                    There was an issue loading preview.
                  </StatusWidget.Title>
                  <StatusWidget.Description>
                    An error report has been sent.
                  </StatusWidget.Description>
                </StatusWidget>
              ) : (
                <VStack>
                  <Document color={Token.color.greyTone20} />
                  <Text color={Token.color.greyTone20}>No documents attached</Text>
                </VStack>
              )}
            </Flex>
          </Cell>
        </VStack>
      )
    }
    if (!canPreview) {
      return (
        <StatusWidget>
          <StatusWidget.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D310.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D310@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D310@3x.png',
            }}
          />
          <StatusWidget.Title>This document can't be previewed</StatusWidget.Title>
        </StatusWidget>
      )
    }
    if (isPdf) {
      return (
        <iframe
          src={`${fileUrl}#view=fitH&toolbar=0&navpanes=0`}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Document"
        />
      )
    }
    if (isImage) {
      return <Image src={fileUrl} />
    }
    return null
  }

  const downloadButton = (
    <MoreBar.Action
      useIcon={Download}
      disabled={Boolean(isLoading || !canSave)}
      onClick={onSave}
    >
      Download
    </MoreBar.Action>
  )

  return (
    <VStack space="s-24">
      <Box>
        <Box mb="s-16">
          {renderActions?.(isLoading, downloadButton) || (
            <MoreBar>{downloadButton}</MoreBar>
          )}
        </Box>
        <Box style={{ height: 'calc(100vh - 230px)' }}>{renderPreview()}</Box>
      </Box>
      {renderDetails?.()}
    </VStack>
  )
}

type DocumentSidebarProps = {
  id: number
  employeeId: number
  isOpen: boolean
  onClose: () => void
}

const DocumentSidebar = ({ id, employeeId, isOpen, onClose }: DocumentSidebarProps) => {
  const theme = useTheme()
  const [document, setDocument] = useState<DocumentInterface>()

  const renderKey = useMemo(() => Math.random(), [id, employeeId])

  if (!isOpen) {
    return null
  }

  return (
    <Fixed
      right={0}
      top={0}
      zIndex={theme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      key={renderKey}
    >
      <Flex height="calc(100vh - 32px)">
        <Side open onClose={onClose} variant="wide" resizable height="100%">
          <Header variant="item">
            <Header.CloseButton aria-label="Close" />
            <SideBarHeaderTitle>Document preview</SideBarHeaderTitle>
            <Header.Bar>
              <MoreBar>
                <MoreBar.Action
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { id, employeeId }),
                    )
                  }
                >
                  Edit
                </MoreBar.Action>
              </MoreBar>
            </Header.Bar>
          </Header>
          <Box mt="-16px">
            <FormPreview
              title="Details"
              data={document}
              api={() =>
                newDocumentRequests.get({
                  id: `${id}`,
                  employeeId: `${employeeId}`,
                })
              }
              onDataLoaded={setDocument}
            >
              <Group>
                <FormPreview.Item title="File name" field="name" />
                <FormPreview.Item
                  title="Issue date"
                  field="issue_date_time"
                  type="date"
                />
                <FormPreview.Item title="Issued by" field="issuer.name" />
                <FormPreview.Item
                  title="Signing date"
                  field="signing_date_time"
                  type="date"
                />
                <FormPreview.Item title="Category" field="category.name" />
              </Group>

              <Box my="s-16">
                <FormPreview.Details title="Description" field="description" />
              </Box>
            </FormPreview>
          </Box>

          <AttachedDocument id={id} document={document} />
        </Side>
      </Flex>
    </Fixed>
  )
}

export default DocumentSidebar
