import React, { useEffect, useState } from 'react'
import { useTheme } from '@src/styles/theme'
import isEmpty from 'lodash/isEmpty'
import SideBar from '@components/SideBar/SideBar'
import {
  ActionButton,
  Bar,
  Box,
  Button,
  chain,
  Checkbox,
  FilterButton,
  Flex,
  Icon,
  IconName,
  Input,
  Link,
  Side,
  Text,
  TextButton,
  Token,
  Widget,
} from '@revolut/ui-kit'
import { CrossCircle, SwitchOff, SwitchOn } from '@revolut/icons'
import { Grid } from '@components/CommonSC/Grid'
import Loader from '@components/CommonSC/Loader'
import { OptionInterface } from '@src/interfaces/selectors'

export interface SidebarOption<T = any> {
  id: number | string
  name: string
  labels?: { name: string; color?: string }[]
  link?: { name: string; href: string }
  data?: T
  disabled?: boolean
  icon?: IconName
}

export interface SidebarMultiselectProps<T = any> {
  options: SidebarOption<T>[]
  onSearch: (query: string, filter?: OptionInterface) => void
  filters?: OptionInterface[]
  searchPending?: boolean
  submitPending?: boolean
  dataType?: string
  isOpen?: boolean
  onCreate?: () => void
  onClose?: () => void
  onSubmit: (allSelected: SidebarOption<T>[]) => void
  submitButtonTitle?: string
  optionsPending?: boolean
  emptySearchMessage?: React.ReactNode
  emptySearchDescription?: React.ReactNode
  initialSearchDescription?: React.ReactNode
  searchActions?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  description?: React.ReactNode
  initialSelection?: SidebarOption[]
  selectedText?: string
  disabled?: boolean
}

type SidebarSelection = { [key: string]: SidebarOption }

const parseInitialSelection = (initialSelection: SidebarOption[]): SidebarSelection => {
  return initialSelection.reduce((result, current) => {
    result[current.id] = current

    return result
  }, {} as SidebarSelection)
}

const SidebarMultiselect = <T,>({
  options,
  onSearch,
  searchPending,
  onCreate,
  onSubmit,
  dataType = 'item',
  isOpen,
  filters,
  onClose,
  submitButtonTitle = 'Add to list',
  submitPending,
  optionsPending,
  emptySearchMessage = 'Search results',
  emptySearchDescription,
  initialSearchDescription,
  searchActions,
  title,
  subtitle,
  description,
  initialSelection,
  selectedText,
  disabled,
}: SidebarMultiselectProps<T>) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedFilter, setSelectedFilter] = useState<OptionInterface>()
  const [showSelected, setShowSelected] = useState(false)
  const [result, setResult] = useState<SidebarSelection>({})

  const selectedCount = Object.keys(result).length

  const theme = useTheme()

  useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && initialSelection) {
      setResult(parseInitialSelection(initialSelection))
    }
  }, [isOpen, initialSelection])

  const checkIfSelectionChanged = (): boolean => {
    if (initialSelection) {
      const containsAllFromInitial = initialSelection.reduce((acc, option) => {
        if (option.id in result) {
          return acc
        }
        return false
      }, true)

      return !containsAllFromInitial || selectedCount !== initialSelection.length
    }
    return !!selectedCount
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    onSearch(value, selectedFilter)
  }

  const handleItemClick = (item: SidebarOption, checked: boolean) => {
    const copyOfResult = { ...result }
    if (checked) {
      delete copyOfResult[`${item.id}`]
      setResult(copyOfResult)

      if (showSelected && !Object.keys(copyOfResult).length) {
        setShowSelected(false)
      }
    } else {
      copyOfResult[`${item.id}`] = item
      setResult(copyOfResult)
    }
  }

  const handleClearAll = () => {
    setResult({})
    setShowSelected(false)
  }

  const reset = () => {
    setSearchValue('')
    setShowSelected(false)
    setResult({})
  }

  const renderItem = (option: SidebarOption, checked: boolean) => {
    const itemDisabled = disabled || option.disabled

    return (
      <Flex
        gap="s-8"
        alignItems="center"
        key={option.id}
        data-testid={`sidebar-multiselect-checkbox-${option.id}`}
        onClick={() => !itemDisabled && handleItemClick(option, checked)}
        style={{ cursor: itemDisabled ? 'not-allowed' : 'pointer' }}
      >
        <Checkbox
          checked={checked}
          disabled={itemDisabled}
          value={option.id}
          onChange={() => !itemDisabled && handleItemClick(option, checked)}
        />
        {option.icon ? <Icon name={option.icon} size={16} /> : null}
        {option?.link && (
          <Link href={option.link?.href} target="_blank" minWidth="fit-content">
            {option.link?.name}
          </Link>
        )}
        <Text color={itemDisabled ? Token.color.greyTone10 : undefined}>
          {option.name}
        </Text>
        {!!option.labels?.length && (
          <Flex flexWrap="wrap">
            {option.labels.map((label, id) => {
              return (
                <Text
                  key={id}
                  fontWeight={500}
                  fontSize="tiny"
                  borderRadius={4}
                  py="s-4"
                  px="s-6"
                  backgroundColor="grey-tone-5"
                  color={label.color}
                  mr={id === option.labels?.length! - 1 ? '0px' : '7px'}
                  mt="s-4"
                >
                  {label.name}
                </Text>
              )
            })}
          </Flex>
        )}
      </Flex>
    )
  }

  const renderSelectedMode = () => {
    const selectedItemsArr = Object.values(result)
    return (
      <>
        <Flex justifyContent="space-between" mb="s-16">
          <Text color="grey-tone-50">Showing selected items</Text>
          {!isEmpty(selectedItemsArr) && (
            <TextButton disabled={disabled} fontWeight={500} onClick={handleClearAll}>
              Clear all
            </TextButton>
          )}
        </Flex>
        <Widget p="18px">
          {optionsPending && <Loader />}
          {!optionsPending && (
            <Grid gap={34}>
              {selectedItemsArr.length ? (
                selectedItemsArr.map(item => {
                  return renderItem(item, !!result[item.id])
                })
              ) : (
                <Text color="grey-tone-50">No selected items...</Text>
              )}
            </Grid>
          )}
        </Widget>
      </>
    )
  }

  const emptyDescription =
    searchValue === '' && initialSearchDescription
      ? initialSearchDescription
      : emptySearchDescription

  const renderSearchMode = () => {
    return (
      <>
        <Flex justifyContent="space-between" mb="s-16">
          <Text color="grey-tone-50">
            {searchValue ? chain('Search results', options.length) : emptySearchMessage}
          </Text>
          <Bar>
            {searchActions}
            {!isEmpty(result) && (
              <TextButton
                disabled={disabled}
                pl="s-6"
                fontWeight={500}
                onClick={handleClearAll}
              >
                Clear all
              </TextButton>
            )}
          </Bar>
        </Flex>
        <Widget p="18px">
          {optionsPending && <Loader />}
          {!optionsPending && (
            <Grid gap={34}>
              {options.length ? (
                options.map((item, key) => {
                  // render only first 20 for performance reasons, use search for showing the rest
                  if (key > 20) {
                    return false // break
                  }
                  return renderItem(item, !!result[item.id])
                })
              ) : (
                <>
                  {!!searchValue && (
                    <Text data-testid="sidebar-multiselect-no-items" color="grey-tone-50">
                      No matching results found...
                    </Text>
                  )}
                  {emptyDescription}
                </>
              )}
            </Grid>
          )}
        </Widget>
      </>
    )
  }

  return (
    <SideBar
      title={
        showSelected ? selectedText || `Selected items` : title || `Select ${dataType}`
      }
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      zIndex={theme.zIndex.sideBar}
    >
      {selectedCount > 0 && (
        <ActionButton
          onClick={() => setShowSelected(!showSelected)}
          useIcon={showSelected ? SwitchOn : SwitchOff}
          mb="s-16"
        >
          {chain('View selected items', selectedCount)}
        </ActionButton>
      )}
      {/* Height 0 is a magic hack to make our position fixed work with ui-kit's native scroll */}
      <Flex height={0} flexDirection="column">
        <Box mb="s-24">
          <Input
            placeholder={`Search ${dataType}`}
            value={searchValue}
            data-testid="sidebar-multiselect-new-input"
            pending={searchPending}
            onChange={e => handleSearchChange(e.currentTarget.value)}
            renderAction={() =>
              searchValue && (
                <CrossCircle
                  size={24}
                  cursor="pointer"
                  color="grey-tone-50"
                  hoverColor="grey-tone-50"
                  onClick={e => {
                    e.preventDefault()
                    setSearchValue('')
                    handleSearchChange('')
                    onSearch('', selectedFilter)
                  }}
                />
              )
            }
          />
        </Box>
        {filters && (
          <Grid gap={8} pb="s-8" mb="s-8" flow="column">
            {filters.map(filter => (
              <FilterButton
                active={filter.id === selectedFilter?.id}
                onClear={() => {
                  setSelectedFilter(undefined)
                  onSearch(searchValue, undefined)
                }}
                onClick={() => {
                  onSearch(searchValue, filter)
                  setSelectedFilter(filter)
                }}
                key={filter.id}
              >
                {filter.name}
              </FilterButton>
            ))}
          </Grid>
        )}
        {onCreate && (
          <ActionButton mb="s-16" onClick={onCreate}>
            Create new {dataType}
          </ActionButton>
        )}
        {description}
        {showSelected ? renderSelectedMode() : renderSearchMode()}
        <Side.Actions>
          {checkIfSelectionChanged() && isOpen && !disabled && (
            <Button
              data-testid="sidebar-multiselect-submit-button"
              onClick={() => onSubmit(Object.values(result))}
              pending={submitPending}
              elevated
            >
              {chain(submitButtonTitle, selectedCount)}
            </Button>
          )}
        </Side.Actions>
      </Flex>
    </SideBar>
  )
}

export default SidebarMultiselect
