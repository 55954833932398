import React from 'react'
import styled from 'styled-components'
import { Color, Icon, Token } from '@revolut/ui-kit'

import Tooltip from '../../Tooltip/Tooltip'
import { KpiInterface } from '@src/interfaces/kpis'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { KPITemplateInterface } from '@src/interfaces/KpiTemplates'
import { colorGetter } from '@src/styles/colors'

const Container = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: start;
  color: ${colorGetter(Color.GREY_20_OPAQUE_90)};
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`

interface Props {
  data: KpiInterface | KPITemplateInterface
}

const ParentLink = ({ data }: Props) => {
  if (data.id === -1) {
    return null
  }
  if (!data.parent) {
    return <span>-</span>
  }
  return (
    <Tooltip text={data.parent.name} placement="left">
      <Container
        onClick={(e: React.MouseEvent) => {
          e.preventDefault()
          e.stopPropagation()
          navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: data.parent?.id }))
        }}
      >
        <Icon name="Link" color={Token.color.greyTone50} size={14} />
      </Container>
    </Tooltip>
  )
}

export default ParentLink
