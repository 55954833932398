import React, { useState } from 'react'
import { AxiosPromise } from 'axios'
import {
  Button,
  Footnote,
  Header,
  MoreBar,
  Popup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'

type Props<T, U> = {
  data: T | null
  onSuccess: (updatedDocument: U, newAssignee?: EmployeeOptionInterface) => void
  disabled: boolean
  updateAction: (document: T, newAssignee: EmployeeOptionInterface) => AxiosPromise<U>
}
export const ChangeAssigneeAction = <
  T extends { id: number; employee: EmployeeOptionInterface | null },
  U extends { id: number },
>({
  data,
  onSuccess,
  disabled,
  updateAction,
}: Props<T, U>) => {
  const [pending, setPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [newAssignee, setNewAssignee] = useState<EmployeeOptionInterface | null>(null)
  const statusPopup = useStatusPopup()

  const actionTitle = data?.employee ? 'Change assignee' : 'Assign employee'

  const onAssign = () => {
    if (!data || !newAssignee) {
      return
    }
    setPending(true)

    updateAction(data, newAssignee)
      .then(res => {
        setPopupOpen(false)
        setPending(false)
        setNewAssignee(null)
        onSuccess(res.data, newAssignee)
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to assign</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .finally(() => {
        setPending(false)
      })
  }

  return (
    <>
      <MoreBar.Action
        useIcon="Profile"
        disabled={disabled}
        onClick={() => setPopupOpen(true)}
      >
        {actionTitle}
      </MoreBar.Action>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        variant="bottom-sheet"
        preventUserClose={pending}
      >
        <Header variant="bottom-sheet">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>{actionTitle}</Header.Title>
        </Header>

        <RadioSelectInput<EmployeeOptionInterface>
          label="Select assignee"
          value={newAssignee}
          selector={selectorKeys.all_employees_avatar_email}
          onChange={val => setNewAssignee(val)}
          allowSetValueToCurrentOption
        />

        <Popup.Actions>
          <Footnote>This will replace the employee already assigned</Footnote>
          <Button onClick={onAssign} elevated pending={pending} disabled={!newAssignee}>
            Assign
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
