import { Flex, Token, IconButton, Box } from '@revolut/ui-kit'
import { QueryViewSelect } from '@src/pages/Forms/QueryForm/components/QueryViewSelect'
import React, { Suspense, useContext, useState } from 'react'
import {
  QueryRunStatuses,
  QueryRunResultInterface,
  QueryInterface,
  ChartSettings,
} from '@src/interfaces/dataAnalytics'
import { QueryResult } from '@src/pages/Forms/QueryForm/components/QueryResult'
import { QueryContext } from '@src/pages/Forms/QueryForm/QueryContextProvider'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { VisualisationSidebar } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/VisualisationSidebar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'

const ExportButtonComponent = React.lazy(() => import('./ExportButton'))

interface QueryResultContainerProps {
  height: number
  queryId: number
  pending: boolean
}

export const QueryResultContainer = connect(
  ({ height, queryId, pending }: QueryResultContainerProps) => {
    const { values } = useLapeContext<QueryInterface>()
    const { fetchingError, fetchingErrorDescription, queryRunResponse, queryRunStatus } =
      useContext(QueryContext)
    const featureFlags = useSelector(selectFeatureFlags)
    const [visualisationSideBarOpen, setVisualisationSideBarOpen] = useState(false)
    const [columns, setColumns] = useState<QueryRunResultInterface>()

    const canManageQuery = featureFlags?.includes(FeatureFlags.ReportingQueriesManagement)

    const handleSettingsChange = (settings: ChartSettings) => {
      values.visualisation = settings
    }

    const viewType = values.visualisation_type || 'table'

    return (
      <>
        <Flex
          backgroundColor={Token.color.widgetBackground}
          borderRadius={12}
          flexDirection="column"
          gap="s-16"
          p="s-16"
          style={{
            height,
            overflow: 'auto',
          }}
        >
          <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
            {canManageQuery && (
              <QueryViewSelect
                onChange={type => {
                  values.visualisation_type = type
                }}
                value={viewType}
              />
            )}
            <Flex alignItems="center" gap="s-16">
              <Suspense fallback={null}>
                <ExportButtonComponent
                  disabled={
                    queryRunStatus !== QueryRunStatuses.Success || !queryRunResponse?.id
                  }
                  fileName={values.name || `${queryId}`}
                  runId={queryRunResponse?.id}
                  queryId={queryId}
                  iconOnly
                />
              </Suspense>
              <IconButton
                aria-label="Visualisation settings"
                disabled={!columns || viewType !== 'chart'}
                color={
                  !columns || viewType !== 'chart'
                    ? Token.color.greyTone20
                    : Token.color.greyTone50
                }
                onClick={() => setVisualisationSideBarOpen(!visualisationSideBarOpen)}
                useIcon="Gear"
              />
            </Flex>
          </Flex>
          <Box height={height - 80}>
            <QueryResult
              fetchingError={fetchingError}
              fetchingErrorDescription={fetchingErrorDescription}
              isLoading={pending}
              runStatus={queryRunStatus}
              runResponse={queryRunResponse}
              tab={viewType}
              setColumns={setColumns}
              chartSettings={values.visualisation}
            />
          </Box>
          {columns ? (
            <VisualisationSidebar
              open={visualisationSideBarOpen}
              onClose={() => setVisualisationSideBarOpen(false)}
              columns={columns}
              chartSettings={values.visualisation}
              onChange={handleSettingsChange}
            />
          ) : null}
        </Flex>
      </>
    )
  },
)
