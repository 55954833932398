import React, { useState } from 'react'
import {
  ManageGoalEntityDefinition,
  ManageGoalsPayload,
  manageGoals,
} from '@src/api/goals'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrgEntityInterface } from '../OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { captureException } from '@sentry/react'
import { roundFloat } from '@src/utils/numbers'
import { GoalWeightMode, GoalsInterface } from '@src/interfaces/goals'
import { useSubmitFlowHelpers } from '@src/pages/Forms/GoalForm/common/utils'
import { Flex, Icon, Text, Token } from '@revolut/ui-kit'
import get from 'lodash/get'

export const useManageGoalsWeights = ({
  entity,
  contentType,
  tableData,
  cycleId,
  onUpdated,
}: {
  entity: OrgEntityInterface | null
  contentType?: number
  tableData: GoalsInterface[]
  cycleId?: number | string
  onUpdated: () => void
}) => {
  const form = useLapeContext<ManageGoalsPayload>()
  const defaultWeightMode =
    (entity && 'goal_weight_mode' in entity.data && entity?.data?.goal_weight_mode?.id) ||
    'automatic'
  const [manageMode, setManageMode] = useState(false)
  const [weightMode, setWeightMode] = useState<GoalWeightMode>(defaultWeightMode)
  const { confirm, confirmationDialog, showError } = useSubmitFlowHelpers()

  const autoDistributeWeights = (): number[] => {
    let sum = 0
    const count = tableData.length
    const roundedWeight = roundFloat(100 / count, 2)
    const minIncrement = 0.01
    const diff = roundFloat(100 - roundedWeight * count, 2) / minIncrement

    return tableData.map((goal, index) => {
      if (index < diff) {
        const weight = (roundedWeight * 100 + minIncrement * 100) / 100

        sum += weight

        return weight
      }
      if (goal === tableData.at(-1)) {
        return roundFloat(100 - sum, 2)
      }

      sum += roundedWeight

      return roundedWeight
    })
  }

  const getManageGoalsEntityDefinition = (): ManageGoalEntityDefinition | null => {
    if (!entity) {
      return null
    }

    if (entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2) {
      return {
        is_company: true,
      }
    }
    if (contentType) {
      return { content_type: { id: contentType }, object_id: entity.data.id }
    }
    return null
  }

  const handleSubmit = async (autodistribute?: boolean) => {
    const values = form.values
    const entityDefinition = getManageGoalsEntityDefinition()

    if (!entityDefinition) {
      captureException('Failed to determine entity definition for manage goals action')
      throw new Error('Please reload page and try again.')
    }

    const autodistributeWeights = autoDistributeWeights()

    const updatedForm = {
      ...form.values,
      ...entityDefinition,
      goal_weight_mode: autodistribute
        ? { id: 'automatic' as const }
        : { id: 'manual' as const },
      goals: values.goals?.map(({ id, weight }, index) => ({
        id,
        weight: autodistribute ? autodistributeWeights[index] : weight,
      })),
      review_cycle: {
        id: String(cycleId),
      },
    }

    try {
      setApiError(null)
      await manageGoals(updatedForm)

      onUpdated()

      if (autodistribute) {
        setWeightMode('automatic')
      }

      setManageMode(false)

      return updatedForm
    } catch (err) {
      const expectedError = get(err, 'response.data.detail.0')
      if (expectedError) {
        setApiError(expectedError)
      } else {
        showError('Failed to update weights.', 'Please try again.')
      }

      throw err
    }
  }

  const totalWeight = form.values.goals?.map(g => g.weight).reduce((a, b) => a + b, 0)

  const [apiError, setApiError] = useState<string | null>(null)

  const validationMessage =
    totalWeight !== 100 ? (
      <Flex alignItems="center" mt="s-16" gap="s-8">
        <Icon name="ExclamationMarkOutline" color={Token.color.orange} size={16} />
        <Text color={Token.color.greyTone50} variant="caption">
          Custom weights defined.{' '}
          {apiError ||
            `The sum of all weights should be 100%, currently ${roundFloat(
              totalWeight,
              2,
            )}%`}
        </Text>
      </Flex>
    ) : null

  return {
    handleSubmit,
    weightMode,
    manageMode,
    toggleManageMode: () => setManageMode(current => !current),
    confirmationDialog,
    autoDistributeWeights: async () => {
      const confirmed = await confirm({
        variant: 'compact',
        label: 'Confirm auto distribution of weights',
        body: 'This will equally distribute the goals weights and overwrite the current values.',
        yesMessage: 'Continue',
        noMessage: 'Cancel',
      })

      if (confirmed.status === 'confirmed') {
        handleSubmit(true)
      }
    },
    validationMessage,
  }
}
