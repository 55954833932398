import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { useGlobalSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { Text, HStack, TextButton, Icon } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import kebabCase from 'lodash/kebabCase'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PUBLIC_ROUTES } from '@src/constants/routes'

type JobPreviewActionsButtonProps = {
  onCopyJobPosting: () => void
}

const JobPreviewActionsButton = ({ onCopyJobPosting }: JobPreviewActionsButtonProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const {
    settings: { career_page_enabled },
  } = useGlobalSettings()
  const isCommercial = useIsCommercial()

  const published =
    values.status &&
    values.status !== PublishingStatuses.closed &&
    values.status !== PublishingStatuses.unpublished
  const showUrl = published && (!isCommercial || career_page_enabled)
  return (
    <HStack>
      <TextButton onClick={onCopyJobPosting}>
        <HStack align="center" space="s-8">
          <Icon name="Copy" size={16} />
          <Text>Copy content</Text>
        </HStack>
      </TextButton>
      {showUrl && (
        <TextButton
          target="_blank"
          to={
            !values.careers_position_url
              ? pathToUrl(PUBLIC_ROUTES.CAREERS.POSITION, {
                  id: `${kebabCase(values.name)}-${values.public_id}`,
                })
              : values.careers_position_url
          }
          use={InternalLink}
        >
          <HStack align="center" space="s-8">
            <Icon name="16/LinkExternal" size={16} />
            <Text>Careers website</Text>
          </HStack>
        </TextButton>
      )}
    </HStack>
  )
}

export default JobPreviewActionsButton
