import React, { useState } from 'react'
import { Button, HStack, MoreBar } from '@revolut/ui-kit'

import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { DYNAMIC_COLUMNS_QUERY_KEY } from '@src/features/GenericEditableTable/constants'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import {
  importGoalsDepartmentColumn,
  importGoalsDescriptionColumn,
  importGoalsEmployeeColumn,
  importGoalsIdColumn,
  importGoalsInitialValueColumn,
  importGoalsIsCompanyColumn,
  importGoalsKPIGoalColumn,
  importGoalsLookUrlColumn,
  importGoalsNameColumn,
  importGoalsOwnerColumn,
  importGoalsReviewCycleColumn,
  importGoalsSQLQueryColumn,
  importGoalsSQLQueryDbColumn,
  importGoalsTargetColumn,
  importGoalsTargetEpicsColumn,
  importGoalsTargetIdColumn,
  importGoalsTargetNameColumn,
  importGoalsTargetTypeColumn,
  importGoalsTeamColumn,
  importGoalsUnitColumn,
} from '@src/constants/columns/importGoals'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { ImportGoalsInterface } from '@src/interfaces/importGoals'
import {
  useHiddenColumnsByQuery,
  useVisibleActionsByQuery,
} from '@src/features/GenericEditableTable/helpers'
import {
  TEMPLATE_TYPE_QUERY_KEY,
  UPLOAD_TYPE_QUERY_KEY,
} from '@src/features/BulkDataImportV2/constants'
import { useQuery } from '@src/utils/queryParamsHooks'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportGoalsInterface>> => ({
  cells: [
    {
      ...importGoalsNameColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsOwnerColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsDescriptionColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsIsCompanyColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsReviewCycleColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsTargetNameColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsInitialValueColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsTargetColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsUnitColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsTeamColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsDepartmentColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsEmployeeColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsIdColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsTargetIdColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsTargetTypeColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsSQLQueryColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsSQLQueryDbColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsKPIGoalColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsLookUrlColumn(options.onChange),
      width: 200,
    },
    {
      ...importGoalsTargetEpicsColumn(options.onChange),
      width: 200,
    },
  ],
})

const optionalBulkSessionFields = [
  'description',
  'is_company',
  'team',
  'department',
  'employee',
  'target_id',
  'target_type',
  'sql_query',
  'sql_query_db',
  'kpi_goal',
  'look_url',
  'target_epics',
  'id',
]

interface EditableGoalsTableProps {
  importRoute: string
  sessionRoute: string
  anyRoute: string
  header: React.ReactNode
}

type GoalLevelField = 'is_company' | 'department' | 'team' | 'employee'

export const EditableGoalsTable = ({
  importRoute,
  sessionRoute,
  anyRoute,
  header,
}: EditableGoalsTableProps) => {
  const { query } = useQuery()
  const hiddenColumns = useHiddenColumnsByQuery(optionalBulkSessionFields)
  const visibleActions = useVisibleActionsByQuery()
  const [goalLevelField, setGoalLevelField] = useState<GoalLevelField>('is_company')

  const extraColumns =
    query[UPLOAD_TYPE_QUERY_KEY] === 'update'
      ? ['target_id', 'id', goalLevelField]
      : [goalLevelField]

  return (
    <BulkDataUploadV2
      preceedingListItems={[
        {
          text: 'Select goals level',
          children: (
            <HStack gap="s-8">
              <Button
                size="xs"
                variant={goalLevelField === 'is_company' ? 'default' : 'white'}
                onClick={() => setGoalLevelField('is_company')}
              >
                Company
              </Button>
              <Button
                size="xs"
                onClick={() => setGoalLevelField('department')}
                variant={goalLevelField === 'department' ? 'default' : 'white'}
              >
                Department
              </Button>
              <Button
                size="xs"
                variant={goalLevelField === 'team' ? 'default' : 'white'}
                onClick={() => setGoalLevelField('team')}
              >
                Team
              </Button>
              <Button
                size="xs"
                onClick={() => setGoalLevelField('employee')}
                variant={goalLevelField === 'employee' ? 'default' : 'white'}
              >
                Employee
              </Button>
            </HStack>
          ),
        },
      ]}
      importRoute={importRoute}
      sessionRoute={sessionRoute}
      anyRoute={anyRoute}
      apiEndpoint={API.GOALS_UPLOAD}
      category="goal_bulk_upload"
      templateParams={{ version: '2', extra_columns: extraColumns.join(',') }}
      name="goals"
      tableName={TableNames.ImportGoals}
      ignoreQueryParams={[
        DYNAMIC_COLUMNS_QUERY_KEY,
        TEMPLATE_TYPE_QUERY_KEY,
        UPLOAD_TYPE_QUERY_KEY,
      ]}
      row={row}
      entity="goal"
      hiddenColumns={hiddenColumns}
      header={header}
      tableActions={tableActionsProps => (
        <MoreBar>
          {visibleActions.owner && (
            <BulkEditAction
              buttonIcon="Profile"
              field="owner"
              selector={selectorKeys.all_employees_avatar_email}
              selectorField="email"
              {...tableActionsProps}
            />
          )}
          {visibleActions.review_cycle && (
            <BulkEditAction
              field="review_cycle"
              label="review cycle"
              selector={selectorKeys.review_cycles}
              {...tableActionsProps}
            />
          )}
          {visibleActions.is_company && (
            <BulkEditAction
              field="is_company"
              label="is company"
              selector={selectorKeys.yes_no_value_options}
              {...tableActionsProps}
            />
          )}
          {visibleActions.unit && (
            <BulkEditAction
              field="unit"
              selector={selectorKeys.kpi_units}
              {...tableActionsProps}
            />
          )}
          {visibleActions.team && (
            <BulkEditAction
              buttonIcon="Suitcase"
              field="team"
              selector={selectorKeys.team}
              {...tableActionsProps}
            />
          )}
          {visibleActions.department && (
            <BulkEditAction
              buttonIcon="Suitcase"
              field="department"
              selector={selectorKeys.department}
              {...tableActionsProps}
            />
          )}
          {visibleActions.employee && (
            <BulkEditAction
              buttonIcon="Profile"
              field="employee"
              selector={selectorKeys.all_employees_avatar_email}
              selectorField="email"
              {...tableActionsProps}
            />
          )}
          {visibleActions.target_type && (
            <BulkEditAction
              field="target_type"
              label="metric type"
              selector={selectorKeys.goal_target_types}
              {...tableActionsProps}
            />
          )}
          {visibleActions.sql_query_db && (
            <BulkEditAction
              buttonIcon="Database"
              field="sql_query_db"
              label="SQL query DB"
              selector={selectorKeys.kpi_sql_query_databases}
              {...tableActionsProps}
            />
          )}
          {visibleActions.sql_query_db && (
            <BulkEditAction
              field="kpi_goal"
              label="KPI goal"
              selector={selectorKeys.kpi_goals}
              {...tableActionsProps}
            />
          )}
          <BulkDeleteButton {...tableActionsProps} />
        </MoreBar>
      )}
    />
  )
}
