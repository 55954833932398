import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, MoreBar, Placeholder, VStack } from '@revolut/ui-kit'

import { useTable } from '@components/Table/hooks'
import {
  DocumentUploadRequestInterface,
  EmployeeDocumentListItemInterface,
  DocumentListItemInterface,
} from '@src/interfaces/documents'
import {
  deleteDocumentUpload,
  newDocumentRequests,
  updateDocumentUploadInfo,
} from '@src/api/documents'
import {
  ImportInterface,
  ImportSessionStatsInterface,
} from '@src/interfaces/bulkDataImport'
import { IdAndName } from '@src/interfaces'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { TableNames } from '@src/constants/table'
import { PermissionTypes } from '@src/store/auth/types'
import { API, selectorKeys } from '@src/constants/api'
import { GenericEditableTable } from '@src/features/GenericEditableTable/GenericEditableTable'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { CreateDocumentCategoryPopup } from '@src/features/GenericEditableTable/CellPopups/CreateDocumentCategory'
import { AttachedDocument } from '@src/features/DocumentSidebar/DocumentSidebar'
import { getEditableTable } from '@src/features/GenericEditableTable/api'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import SideBar from '@components/SideBar/SideBar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ChangeAssigneeAction } from './common/ChangeAssigneeAction'
import { PreviewDocSelectorBar } from './common/PreviewDocSelectorBar'
import { AddDocumentSidebar } from './AddDocumentSidebar'
import { existingItemsRow } from './row'

type CreateNewItemType = 'category'

const useGetDocumentUploadBySelectedTableItem = (
  tableItemToPreview: DocumentListItemInterface | null,
) => {
  const [documentUploadToPreview, setDocumentUploadToPreview] =
    useState<DocumentUploadRequestInterface | null>(null)

  useEffect(() => {
    if (!tableItemToPreview) {
      return
    }
    newDocumentRequests
      .get({
        id: String(tableItemToPreview.recipient_id),
        employeeId: String(tableItemToPreview.employee.id),
      })
      .then(res => {
        setDocumentUploadToPreview(res.data)
      })
  }, [tableItemToPreview?.id])

  return { documentUploadToPreview, setDocumentUploadToPreview }
}

export const DocumentsList = () => {
  const permissions = useSelector(selectPermissions)
  const showDocumentButtons = permissions.includes(PermissionTypes.UploadDocuments)

  const [tableItemToPreview, setTableItemToPreview] =
    useState<DocumentListItemInterface | null>(null)
  const { documentUploadToPreview, setDocumentUploadToPreview } =
    useGetDocumentUploadBySelectedTableItem(tableItemToPreview)

  const [isAddNewSidebarOpen, setIsAddNewSidebarOpen] = useState(false)
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    onChangeAction: (entity: IdAndName) => void
  }>()

  const onCreateNew = (
    type: CreateNewItemType,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, onChangeAction })
  }

  const table = useTable<
    ImportInterface<EmployeeDocumentListItemInterface>,
    ImportSessionStatsInterface
  >(
    {
      getItems: getEditableTable<EmployeeDocumentListItemInterface>(
        `${API.EMPLOYEES}/documents`,
        undefined,
      ),
    },
    [{ columnName: 'source', filters: [{ id: 'upload', name: 'upload' }] }],
    [{ sortBy: 'issued_date_time', direction: SORT_DIRECTION.ASC }],
  )
  const previewItems =
    table.data.map(d => ({ id: d.data.id, name: d.data.document_name })) || []

  return (
    <>
      <GenericEditableTable
        table={table}
        apiEndpoint={`${API.EMPLOYEES}/documents`}
        tableName={TableNames.DocumentsOnboarding}
        row={existingItemsRow(onCreateNew)}
        entity="document"
        variant="existingEntities"
        hiddenColumns={{ action: false }}
        onActionColumnPreview={data => {
          const { data: tableItemData } = data
          setTableItemToPreview(tableItemData)
        }}
        getCustomEditCellAction={async data => {
          let response
          try {
            response = await updateDocumentUploadInfo(
              data.employee.id,
              data.recipient_id,
              {
                // name field key doesn't match between table list API (`document_name`) and item edit API (`name`)
                name: data.document_name,
                category: data.category,
                employee: data.employee,
              } as DocumentUploadRequestInterface,
            )
          } catch (error) {
            // since the name field key doesn't match (see the comment above),
            // we need to map errors data here as well
            if ('name' in error.response?.data || {}) {
              error.response.data.document_name = error.response.data.name
            }
            throw error
          }
          return response
        }}
        existingEntitiesDeleteHandler={rowId => {
          const { data: documentToDelete } =
            table.data.find(doc => doc.id === rowId) || {}

          if (!documentToDelete?.recipient_id || !documentToDelete?.employee) {
            throw new Error("This document upload can't be deleted")
          }
          return deleteDocumentUpload(String(documentToDelete.id))
        }}
        tableActions={tableActionProps => (
          <MoreBar>
            {showDocumentButtons && (
              <>
                <MoreBar.Action
                  useIcon="Plus"
                  onClick={() => setIsAddNewSidebarOpen(!isAddNewSidebarOpen)}
                >
                  Add document
                </MoreBar.Action>
                <MoreBar.Action
                  to={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.FILES)}
                  use={InternalLink}
                  useIcon="ShareIOs"
                >
                  Import documents
                </MoreBar.Action>
              </>
            )}
            <BulkEditExistingEntitiesAction
              sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
              buttonIcon="Suitcase"
              field="category"
              selector={selectorKeys.document_categories}
              {...tableActionProps}
              apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
              fieldsForBulkEdit={['name', 'employee', 'category']}
            />
            <BulkEditExistingEntitiesAction
              sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}
              buttonIcon="Profile"
              field="employee"
              label="assignee"
              selector={selectorKeys.all_employees_avatar_email}
              selectorField="email"
              {...tableActionProps}
              apiEndpoint={API.DOCUMENTS_UPLOADS_BULK}
              fieldsForBulkEdit={['name', 'employee', 'category']}
            />
          </MoreBar>
        )}
        emptyState={
          <Box mt="s-16">
            <Placeholder>
              <Placeholder.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images-v2/3D299.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png',
                }}
              />
              <Placeholder.Title>Your documents will appear here</Placeholder.Title>
            </Placeholder>
          </Box>
        }
      />
      <AddDocumentSidebar
        isOpen={isAddNewSidebarOpen}
        onClose={() => setIsAddNewSidebarOpen(false)}
        onSubmit={() => {
          table.refresh()
          setIsAddNewSidebarOpen(false)
        }}
      />
      <CreateDocumentCategoryPopup
        open={createNewTypeState?.type === 'category'}
        onSuccess={newCategory => {
          createNewTypeState?.onChangeAction(newCategory)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
      <SideBar
        title="Document preview"
        subtitle={
          documentUploadToPreview ? (
            <UserWithAvatar {...documentUploadToPreview.employee} />
          ) : undefined
        }
        isOpen={!!tableItemToPreview}
        onClose={() => {
          setTableItemToPreview(null)
          setDocumentUploadToPreview(null)
        }}
        sideProps={{ resizable: true }}
      >
        {tableItemToPreview && (
          <AttachedDocument<DocumentUploadRequestInterface>
            id={Number(tableItemToPreview.recipient_id)}
            document={documentUploadToPreview}
            pending={!documentUploadToPreview}
            renderActions={(isLoading, downloadButton) => (
              <VStack space="s-16">
                <MoreBar>
                  {downloadButton}
                  <ChangeAssigneeAction<
                    DocumentListItemInterface,
                    DocumentUploadRequestInterface
                  >
                    data={tableItemToPreview}
                    disabled={isLoading}
                    updateAction={(data, newAssignee) => {
                      return updateDocumentUploadInfo(
                        data.employee.id,
                        data.recipient_id,
                        {
                          // name field key doesn't match between table list API (`document_name`) and item edit API (`name`)
                          name: data.document_name,
                          category: data.category,
                          employee: newAssignee,
                        },
                      )
                    }}
                    onSuccess={updatedDocument => {
                      setDocumentUploadToPreview(updatedDocument)
                      table.refresh()
                    }}
                  />
                </MoreBar>
                <PreviewDocSelectorBar
                  items={previewItems}
                  selectedItem={{
                    id: tableItemToPreview.id,
                    name: tableItemToPreview?.document_name,
                  }}
                  setSelectedItem={selectedItem => {
                    const itemToPreview = table.data.find(d => d.id === selectedItem.id)
                    if (itemToPreview) {
                      setTableItemToPreview(itemToPreview.data)
                    }
                  }}
                />
              </VStack>
            )}
          />
        )}
      </SideBar>
    </>
  )
}
