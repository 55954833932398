import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { format } from 'date-fns'
import { AxiosResponse } from 'axios'
import { connect, useLape } from 'lape'
import cloneDeep from 'lodash/cloneDeep'
import { ActionButton, Box, Cell as Card, Checkbox, Color } from '@revolut/ui-kit'

import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { RadioSelect } from '@components/Inputs/RadioSelect/RadioSelect'
import {
  DeliverableQualityCriteria,
  DeliverableScorecardStatus,
  DeliverableSettingsInterface,
  DeliverableStatsInterface,
  FunctionalDeliverableInterface,
  FunctionalDeliverableScorecardInterface,
} from '@src/interfaces/deliverables'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import {
  changeScorecard,
  getDeliverablesStats,
  getJiraTickets,
  postScorecard,
  refreshDeliverablesTickets,
} from '@src/api/deliverables'
import Icon, { Icons } from '@components/Icon/Icon'
import { selectUser } from '@src/store/auth/selectors'
import {
  DeliverableOptions,
  FunctionalCommentInterface,
  ReviewerRelation,
} from '@src/interfaces/performance'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { CheckMarkFilter } from '@components/Inputs/Filters/FilterSelect/CheckMarkFilter/CheckMarkFilter'
import { useTable } from '@components/Table/hooks'
import Tooltip from '@components/Tooltip/Tooltip'
import { COOKIE, selectorKeys } from '@src/constants/api'
import { getIconKeyByIssue, ratingToColor } from '@src/utils/performance'
import { pushError, pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import {
  commentColumn,
  ignoreColumn,
  issueTypeColumn,
  ratingColumn,
  resolutionDateColumn,
  taskColumn,
} from '@src/constants/columns/deliverables'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import { colorGetter, getColor } from '@src/styles/colors'
import { Grid } from '@components/CommonSC/Grid'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { Welcome } from './Welcome'
import CommentCell from './CommentCell'
import FunctionalCommentDialog from './FunctionalCommentDialog'
import { RATING_OPTIONS } from './constants'
import { getCutOffText, getDeliverableScorecard, isIgnoredTicket } from './utils'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const USER_REVIEW_STATUS_KEY = 'user_review_status'
const CYCLE_OFFSET_KEY = 'review_cycle__offset'

const Cell = styled.div<{ backgroundColor?: string | null; highlighted?: boolean }>`
  padding: 0;
  background-color: ${props =>
    props.highlighted ? 'rgba(247, 147, 13, 0.05)' : props.backgroundColor};
  border: ${props => (props.highlighted ? `1px solid ${props.theme.colors.orange}` : 0)};
  height: 50px;
  line-height: 50px;
  overflow: hidden;
`

const JiraLink = styled.a`
  color: ${colorGetter(Color.BLUE)};
`

const IgnoreWrap = styled(Cell)`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const RadioSelectStyled = styled(RadioSelect)<{ backgroundColor: string | null }>`
  padding: 0 16px;
  background-color: ${props => props.backgroundColor || 'inherit'};
  height: 50px;
`

const Key = styled(Cell)<{ ignored?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${props => (props.ignored ? 0.5 : 1)};
`

const IssueType = styled.div<{ ignored?: boolean }>`
  display: grid;
  justify-content: center;
  width: 100%;
  opacity: ${props => (props.ignored ? 0.5 : 1)};
`

const IgnoredText = styled.span<{ ignored?: boolean }>`
  color: ${props => (props.ignored ? '#8B959E' : 'inherit')};
`

const initialFilter = [
  {
    filters: [{ name: `0`, id: 0 }],
    columnName: CYCLE_OFFSET_KEY,
    nonResettable: true,
  },
]

const initialSortBy = [
  {
    sortBy: 'resolution_date_time',
    direction: SORT_DIRECTION.ASC,
  },
]

interface Props {
  onChangeScorecard: (
    ticketId: number,
    type: string,
    value: DeliverableOptions | boolean,
    scorecard?: FunctionalDeliverableScorecardInterface,
  ) => void
  user: EmployeeInterface
  onClickComment: (
    ticketId: number,
    ticketKey: string,
    scorecard?: FunctionalDeliverableScorecardInterface,
  ) => void
  selfReview: boolean
  canEdit: boolean
}

const ROW = ({
  onChangeScorecard,
  user,
  onClickComment,
  canEdit,
}: Props): RowInterface<FunctionalDeliverableInterface> => ({
  highlight: (deliverable, theme) => {
    const scorecard = getDeliverableScorecard(deliverable)
    const ignored = isIgnoredTicket(deliverable, scorecard)
    return ignored ? getColor(theme, Color.GREY_TONE_2) : ''
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...resolutionDateColumn,
      width: 150,
      insert: ({ data: deliverable }) => {
        const scorecard = getDeliverableScorecard(deliverable)
        const ignored = isIgnoredTicket(deliverable, scorecard)

        return (
          <Cell>
            <IgnoredText ignored={ignored}>
              {deliverable.resolution_date_time
                ? format(new Date(deliverable.resolution_date_time), 'd MMM yyyy')
                : '-'}
            </IgnoredText>
          </Cell>
        )
      },
    },
    {
      ...issueTypeColumn,
      width: 48,
      insert: ({ data: deliverable }) => {
        const iconType = getIconKeyByIssue(deliverable.issue_type)
        const scorecard = getDeliverableScorecard(deliverable)
        const ignored = isIgnoredTicket(deliverable, scorecard)

        return (
          <IssueType ignored={ignored}>
            <Tooltip placement="top" text={deliverable.issue_type}>
              <Icon type={iconType as Icons} size="tiny" />
            </Tooltip>
          </IssueType>
        )
      },
    },
    {
      ...taskColumn,
      width: 520,
      insert: ({ data: deliverable }) => {
        const scorecard = getDeliverableScorecard(deliverable)
        const ignored = isIgnoredTicket(deliverable, scorecard)

        return (
          <Key ignored={ignored}>
            <JiraLink href={deliverable.url} target="_blank" rel="noopener norefferer">
              {deliverable.key}
            </JiraLink>
            : <IgnoredText ignored={ignored}>{deliverable.title}</IgnoredText>
          </Key>
        )
      },
    },
    {
      ...ignoreColumn,
      width: 12,
      resizeWidth: 50,
      headerTooltip: (
        <TooltipContainer>
          You can ignore an issue if you don't have enough knowledge to evaluate it; you
          can undo it by unchecking the ignore checkbox.
        </TooltipContainer>
      ),
      insert: ({ data: deliverable }) => {
        const scorecard = getDeliverableScorecard(deliverable)
        const ignored = isIgnoredTicket(deliverable, scorecard)
        const notSelf = !!scorecard?.reviewer?.id && scorecard.reviewer.id !== user.id

        const disabled = !canEdit || notSelf || deliverable.loading

        let tooltipText

        if (disabled) {
          tooltipText = 'You are not allowed to change the field at this time'
        }

        if (deliverable.filtered_aggregation?.locked) {
          tooltipText = getCutOffText(deliverable.cycle?.name)
        }

        return (
          <IgnoreWrap>
            <Tooltip placement={'top'} hide={!tooltipText} text={tooltipText}>
              <Checkbox
                disabled={!!tooltipText || disabled}
                onChange={event => {
                  onChangeScorecard?.(
                    deliverable.id,
                    'ignored',
                    event.target.checked,
                    scorecard,
                  )
                }}
                checked={ignored}
                data-testid="ignore-checkbox"
              />
            </Tooltip>
          </IgnoreWrap>
        )
      },
    },
    {
      ...ratingColumn,
      width: 142,
      resizeWidth: 10,
      insert: ({ data: deliverable, theme }) => {
        const scorecard = getDeliverableScorecard(deliverable)
        const ignored = isIgnoredTicket(deliverable, scorecard)
        const notSelf = !!scorecard?.reviewer?.id && scorecard.reviewer.id !== user.id

        let value = {
          id: '',
          name: '',
          text: '',
        }

        const ratingOption = RATING_OPTIONS.find(
          option => option.id === scorecard?.user_rating,
        )

        if (ratingOption && !ignored) {
          value = ratingOption
        }

        const tooltip = {
          tooltipTitle: value.name,
          tooltipText: value.text,
        }

        let locked = canEdit && notSelf

        if (deliverable.filtered_aggregation?.locked) {
          tooltip.tooltipTitle = ''
          tooltip.tooltipText = getCutOffText(deliverable.cycle?.name)
          locked = true
        }

        const disabled = locked || ignored || !canEdit

        return (
          <RadioSelectStyled
            id={`select_rating__${deliverable.id}`}
            options={RATING_OPTIONS}
            onChange={option => {
              onChangeScorecard?.(
                deliverable.id,
                'user_rating',
                option.id as DeliverableOptions,
                scorecard,
              )
            }}
            value={value}
            width={480}
            placeholder={disabled ? '-' : undefined}
            {...tooltip}
            disabled={disabled}
            locked={!ignored && locked}
            backgroundColor={
              ignored
                ? getColor(theme, Color.GREY_TONE_2)
                : ratingToColor(theme, scorecard?.user_rating)
            }
            loading={deliverable.loading}
          />
        )
      },
    },
    {
      ...commentColumn,
      width: 500,
      notHoverable: true,
      insert: ({ data: deliverable }) => {
        const scorecard = getDeliverableScorecard(deliverable)
        const ignored = isIgnoredTicket(deliverable, scorecard)

        return (
          <Cell>
            <CommentCell
              scorecard={scorecard}
              deliverableId={deliverable.id}
              deliverableKey={deliverable.key}
              onClickComment={onClickComment}
              locked={deliverable.filtered_aggregation?.locked}
              canEdit={canEdit}
              ignored={ignored}
              comment={deliverable.filtered_aggregation?.comment}
              loading={deliverable.loading}
            />
          </Cell>
        )
      },
    },
  ],
})

export interface DeliverablesProps {
  settings: DeliverableSettingsInterface
  data: EmployeeInterface
}

const Deliverables = ({ settings, data }: DeliverablesProps) => {
  const selfReview = settings.permissions.relation === ReviewerRelation.Self
  const canEdit = settings.permissions.can_review && !selfReview
  const [comment, setComment] = useState<FunctionalCommentInterface | undefined>()
  const [refreshPending, setRefreshPending] = useState(false)
  const [isWelcomePage, setWelcomePage] = useState(
    !workspaceLocalStorage.getItem(COOKIE.DELIVERABLES_INFO_SEEN),
  )

  // using lape state here to avoid side-effects in setDate in table hooks for multiple parallel requests
  const state = useLape<{
    data: FunctionalDeliverableInterface[]
  }>({
    data: [],
  })

  const user = useSelector(selectUser)

  const table = useTable<FunctionalDeliverableInterface, DeliverableStatsInterface>(
    {
      getItems: params => getJiraTickets(data.id, params),
      getStats: params => getDeliverablesStats(data.id, params),
    },
    initialFilter,
    initialSortBy,
  )

  useEffect(() => {
    state.data = [...table.data]
  }, [table.data])

  const updateTable = (
    ticketId: number,
    responseData?: FunctionalDeliverableScorecardInterface,
    scorecardId?: number,
    type?: string,
    value?: DeliverableOptions | boolean | string | DeliverableQualityCriteria[],
  ) => {
    const item = state.data.find(ticket => ticket.id === ticketId)

    if (item) {
      const scorecardIndex = item.deliverable_scorecards.findIndex(
        scorecard => scorecard.id === scorecardId,
      )

      if (scorecardIndex !== -1) {
        if (responseData) {
          item.deliverable_scorecards.splice(scorecardIndex, 1, responseData)
        } else {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          item.deliverable_scorecards[scorecardIndex][type!] = value
        }
      } else if (responseData) {
        item.deliverable_scorecards.push(responseData)
      } else {
        // pushing fake scorecard
        item.deliverable_scorecards.push({
          id: Math.round(Math.random() * 1000000),
          reviewer: {
            id: user.id,
            full_name: user.full_name,
            display_name: user.display_name,
            status: user.status.id as IdStatuses,
            avatar: user.avatar,
          },
          reviewer_relation: ReviewerRelation.FunctionalManager,
          [type!]: value,
        })
      }
    }
  }

  const setRowLoading = (ticketId: number, loading: boolean) => {
    const item = state.data.find(ticket => ticket.id === ticketId)

    if (item) {
      item.loading = loading
    }
  }

  const onChangeScorecard = async (
    ticketId: number,
    type: string,
    value?: DeliverableOptions | boolean | string | DeliverableQualityCriteria[],
  ) => {
    const fallbackData = cloneDeep(state.data)
    const scorecard = state.data
      .find(deliverable => deliverable.id === ticketId)
      ?.deliverable_scorecards?.find(item => item.reviewer?.id === user.id)

    let response: AxiosResponse<FunctionalDeliverableScorecardInterface>

    try {
      if (scorecard) {
        // optimistic changes
        updateTable(ticketId, undefined, scorecard?.id, type, value)
        response = (await changeScorecard(data.id, scorecard.id, {
          [type]: value,
        })) as AxiosResponse<FunctionalDeliverableScorecardInterface>
      } else {
        setRowLoading(ticketId, true)
        response = (await postScorecard(data.id, {
          functional_issue: ticketId,
          [type]: value,
          reviewer_relation: ReviewerRelation.FunctionalManager,
          reviewer: user,
        })) as AxiosResponse<FunctionalDeliverableScorecardInterface>
        setRowLoading(ticketId, false)
      }

      // replace with real data
      updateTable(ticketId, response.data, scorecard?.id)

      table.refreshStats()
    } catch (e) {
      // fallback old data if something went wrong
      state.data = fallbackData
    }
  }

  const filtersTickets = [
    {
      key: DeliverableScorecardStatus.Pending,
      label: 'Pending evaluation',
      summary: `${table.stats?.pending_count || 0}`,
    },
    {
      key: DeliverableScorecardStatus.Completed,
      label: 'Evaluated',
      summary: `${table.stats?.completed_count || 0}`,
    },
    {
      key: DeliverableScorecardStatus.Ignored,
      label: 'Ignored',
      summary: `${table.stats?.ignored_count || 0}`,
    },
  ]

  const onClickComment = (
    ticketId: number,
    ticketKey: string,
    scorecard?: FunctionalDeliverableScorecardInterface,
  ) => {
    setComment({
      scorecard,
      ticketId,
      ticketKey,
    })
  }

  if (isWelcomePage) {
    return (
      <Welcome
        onClose={() => {
          setWelcomePage(false)
          workspaceLocalStorage.setItem(COOKIE.DELIVERABLES_INFO_SEEN, '1')
        }}
      />
    )
  }

  const refreshTicketsRequest = async () => {
    try {
      setRefreshPending(true)
      const selectedCycles =
        table.filterBy
          ?.find(
            (filterBy: FilterByInterface) => filterBy.columnName === CYCLE_OFFSET_KEY,
          )
          ?.filters.map(option => +option.id) || []

      const resp = await refreshDeliverablesTickets(data.id, selectedCycles)

      if (resp.data.accepted) {
        pushNotification({
          value:
            'You request to refresh jira tickets is added to queue and will be executed shortly.',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      } else {
        pushNotification({
          value: 'Something went wrong. Please try later.',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.error,
        })
      }
    } catch (e) {
      pushError({ error: e })
    } finally {
      setRefreshPending(false)
    }
  }

  return (
    <Card width="100%">
      <Box width="100%">
        <FunctionalCommentDialog
          open={!!comment}
          onClose={() => {
            setComment(undefined)
          }}
          onSubmit={value => {
            onChangeScorecard(comment!.ticketId!, 'comment', value)
            setComment(undefined)
          }}
          value={comment?.scorecard?.comment}
          title={`Comments (${comment?.ticketKey})`}
        />
        <Box mb="s-16">
          <Grid flow="column" gap={16} justifyContent="start">
            <CycleFilter
              onFilterChange={table.onFilterChange}
              columnName={CYCLE_OFFSET_KEY}
              filter={table.filterBy}
              type={CycleFilterType.NewUI}
              selector={selectorKeys.deliverable_cycle_offsets}
            />
          </Grid>
          {canEdit && (
            <ActionButton
              pending={refreshPending}
              onClick={refreshTicketsRequest}
              mt="s-16"
            >
              Refresh ticket list
            </ActionButton>
          )}

          <CheckMarkFilter
            filters={filtersTickets}
            onFilterChange={table.onFilterChange}
            filter={table.filterBy}
            columnName={USER_REVIEW_STATUS_KEY}
          />
        </Box>
        <AdjustableTable
          name={TableNames.EmployeeDeliverables}
          row={ROW({
            user,
            selfReview,
            onChangeScorecard,
            onClickComment,
            canEdit,
          })}
          noReset
          {...table}
          data={state.data}
          useWindowScroll
        />
      </Box>
    </Card>
  )
}

export default connect(Deliverables)
