import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { goalsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { GoalsIntro } from './GoalsIntro'
import { GoalsSetup } from './GoalsSetup'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { OrgEntityProvider } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { useGetCompany } from '@src/api/company'
import { GoalRouterWithForm } from '@src/pages/Forms/GoalForm'
import { GoalsSettings } from '../../Settings/Goals/Goals'
import { ImportGoals } from './ImportGoals'
import { CycleDuration } from './CycleDuration'

export const setupCompanyGoalsTitle = 'Setup company goals'
export const selectCycleFrequencyTitle = 'Select cycle frequency'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.INTRO,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: GoalsIntro,
  },
  {
    title: selectCycleFrequencyTitle,
    path: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.CYCLE,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.CYCLE,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: CycleDuration,
    hideActions: true,
    hidePageBody: true,
  },
  {
    title: setupCompanyGoalsTitle,
    path: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.ANY,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: GoalsSetup,
    isWide: true,
    hideActions: true,
    hidePageBody: true,
  },
]

export const Goals = () => {
  const { data } = useGetCompany()

  return (
    <OrgEntityProvider
      entityType={EntityTypes.company}
      entity={
        data
          ? {
              type: EntityTypes.company,
              data,
            }
          : undefined
      }
    >
      <Switch>
        <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.ANY}>
          <GoalRouterWithForm />
        </Route>

        <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.ANY}>
          <ImportGoals />
        </Route>

        <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.ANY}>
          <GoalsSettings
            isOnboarding
            allRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.ALL}
            jiraIntegrationRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.INTEGRATIONS.JIRA
            }
            targetTypesRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.TARGET_TYPES}
            approvalProcessRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.APPROVAL_PROCESS
            }
            roadmapsRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.ROADMAPS}
            templatesRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.TEMPLATES.LIST}
            rulesRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.RULES.LIST}
            backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL}
          />
        </Route>

        <Route>
          <OnboardingChecklistContent config={goalsConfig} routes={routes} />
        </Route>
      </Switch>
    </OrgEntityProvider>
  )
}
