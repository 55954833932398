import React, { useMemo } from 'react'
import { PageBody } from '@components/Page/PageBody'
import OnboardingActions from '@src/pages/OnboardingChecklistV2/components/OnboardingActions'
import { goalsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { API, selectorKeys } from '@src/constants/api'
import { performanceSettings } from '@src/api/performanceSettings'
import { selectCycleFrequencyTitle } from '@src/pages/OnboardingChecklistV2/Goals/index'
import { CYCLE_DURATION, PerformanceSettingsInterface } from '@src/interfaces/settings'
import { connect } from 'lape'
import { useGetSelectors } from '@src/api/selectors'
import { RecommendedLabel } from '@src/pages/OnboardingChecklist/components/Recommended'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { generateInitialCycles } from '@src/api/reviewCycles'
import { Text, Token, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const CycleDurationContent = () => {
  const { dirty } = useLapeContext<PerformanceSettingsInterface>()
  const { data: durations } = useGetSelectors(selectorKeys.cycle_durations)

  const options = useMemo(() => {
    if (durations && durations.length) {
      const descriptionsById = {
        quarterly:
          'Conducted every three months, these reviews offer a more frequent opportunity for feedback and goal adjustments',
        biannually:
          'Held twice a year, these reviews provide a midpoint check-in to assess progress and set goals for the next period',
        annually:
          "Traditional annual reviews are conducted once a year. They offer a comprehensive overview of an employee's performance over the past year",
      }
      return durations.map(option => ({
        value: option,
        description: descriptionsById[option.id as CYCLE_DURATION],
        label:
          option.id === 'quarterly' ? (
            <RecommendedLabel label={option.name} />
          ) : (
            option.name
          ),
      }))
    }
    return []
  }, [durations])
  const { data: reviewCycles } = useGetSelectors(selectorKeys.review_cycles)

  // we have 3 cases:
  // case 1 - no review cycles exist yet (!!reviewCycles?.length === false)
  //    on Next click sending request to set cycle duration
  //    onBeforeSubmit sending request to generate cycles
  // case 2 - review cycles exist, but form didn't change (!!reviewCycles?.length === true && dirty === false)
  //    on Next click sending request to set cycle duration with empty payload
  // case 3 - review cycles exist, and form has changes (!!reviewCycles?.length === true && dirty === true)
  //    on Next click sending request to set selected cycle duration
  //    onBeforeSubmit sending request to re-generate cycles
  const regenerateCycles = !!reviewCycles?.length && dirty

  const onBeforeSubmit = async () => {
    if (!reviewCycles?.length || regenerateCycles) {
      await generateInitialCycles()
    }
  }

  return (
    <>
      <PageBody>
        <VStack space="s-8" mb="s-16">
          <Text variant="h5">How often do you want to do performance reviews?</Text>
          <Text variant="small" color={Token.color.greyTone50}>
            Select how often the company will set goals and review employee performance
          </Text>
        </VStack>
        <LapeNewRadioButtons
          variant="group-items"
          highlightSelected
          name="cycle_duration"
          options={options}
        />
      </PageBody>

      <OnboardingActions
        config={goalsConfig}
        currentStep={selectCycleFrequencyTitle}
        isForm
        isLastStep={false}
        updateSteps
        disableNext={false}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL}
        onBeforeSubmit={onBeforeSubmit}
        confirmationDialogue={
          regenerateCycles
            ? {
                body: 'In order to change the cycle frequency we will delete all the cycles created before. All the related items (e.g. goals) will need to be created again',
                label: 'Delete all existing review cycles?',
                yesMessage: 'Yes, delete',
                noMessage: 'No, cancel',
                variant: 'compact',
                yesBtnVariant: 'negative',
              }
            : undefined
        }
      />
    </>
  )
}

export const CycleDuration = connect(() => {
  return (
    <Form
      api={performanceSettings}
      invalidateQueries={[API.PERFORMANCE_SETTINGS]}
      forceParams={{ id: 'cycle' }}
      isExistingForm
    >
      <CycleDurationContent />
    </Form>
  )
})
