import React, { Suspense, memo, useMemo } from 'react'
import { Box, Color, ErrorWidget, Flex, Skeleton, Spinner } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { getQueryRunResult } from '@src/api/dataAnalytics'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import {
  ChartSettings,
  QueryRunResultInterface,
  QueryViewType,
} from '@src/interfaces/dataAnalytics'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { VisualisationType } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/VisualisationSidebar'
import { Counter } from '@src/pages/Forms/QueryForm/components/Visualisation/Counter'

const BarChartComponent = React.lazy(() => import('./Visualisation/Bar'))
const ComboChartComponent = React.lazy(() => import('./Visualisation/Combo'))
const BarHorizontalChartComponent = React.lazy(
  () => import('./Visualisation/BarHorizontal'),
)
const LineChartComponent = React.lazy(() => import('./Visualisation/Line'))
const PieChartComponent = React.lazy(() => import('./Visualisation/Pie'))

interface VisualisationResultProps {
  runId: number
  tab?: QueryViewType
  queryId: number
  setColumns: (data: QueryRunResultInterface) => void
  chartSettings: ChartSettings
}

interface ChartContentProps {
  chartType?: VisualisationType
  data: QueryRunResultInterface
  chartSettings: ChartSettings
}

export const ChartContent = ({ chartType, data, chartSettings }: ChartContentProps) => {
  switch (chartType) {
    case 'bar':
      return (
        <BarChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
        />
      )
    case 'combo':
      return (
        <ComboChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
        />
      )
    case 'row':
      return (
        <BarHorizontalChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
        />
      )
    case 'line':
      return (
        <LineChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
        />
      )
    case 'pie':
      return (
        <PieChartComponent
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
        />
      )
    case 'counter':
      return (
        <Counter
          data={data}
          xValue={chartSettings.xValue}
          yValue={chartSettings.yValue}
          title={chartSettings.title}
          subtitle={chartSettings.subtitle}
        />
      )
    default:
      return null
  }
}

export const VisualisationResult = memo(
  ({ runId, queryId, tab, setColumns, chartSettings }: VisualisationResultProps) => {
    const table = useTable({
      getItems: getQueryRunResult(runId, queryId),
    })

    const tableRows = useMemo(() => {
      setColumns(table.columns)

      const cells = table.columns.map(
        key =>
          ({
            type: CellTypes.insert,
            idPoint: key.name,
            dataPoint: key.name,
            sortKey: null,
            filterKey: null,
            selectorsKey: selectorKeys.none,
            title: key.name,
            width: 300,
            insert: ({ data }) => {
              return typeof data[key.name] === 'boolean'
                ? data[key.name].toString()
                : data[key.name]
            },
          } as ColumnCellInterface<any>),
      )

      return { cells }
    }, [table.columns])

    if (table.loading) {
      return (
        <Flex alignItems="center" flex="1 0" justifyContent="center">
          <Spinner size={48} color={Color.BLUE} />
        </Flex>
      )
    }

    if (table.fetchError) {
      return (
        <Flex
          alignItems="center"
          flex="1 0"
          flexDirection="column"
          justifyContent="center"
        >
          <ErrorWidget>
            <ErrorWidget.Title>
              <ExclamationTriangle color={Color.RED} />
            </ErrorWidget.Title>
            <ErrorWidget.Description>{table.fetchError.message}</ErrorWidget.Description>
          </ErrorWidget>
        </Flex>
      )
    }

    return (
      <>
        {tab === 'chart' ? (
          <Flex gap="s-16" height="100%" width="100%">
            <Suspense fallback={<Skeleton />}>
              <ChartContent
                chartType={chartSettings.chartType || 'line'}
                data={table.data}
                chartSettings={chartSettings}
              />
            </Suspense>
          </Flex>
        ) : (
          <Box overflow="auto" width="100%">
            <AdjustableTable
              hideCountAndButtonSection
              name={TableNames.ReportingAppQueriesRunResult}
              noDataMessage="No data to display"
              row={tableRows}
              {...table}
            />
          </Box>
        )}
      </>
    )
  },
)
