import React from 'react'
import { EngagementResultsComment } from '@src/interfaces/engagement'
import {
  Avatar,
  Box,
  Cell,
  chain,
  Circle,
  Color,
  HStack,
  Item,
  Tag,
  Text,
  VStack,
} from '@revolut/ui-kit'
import { formatDateTime } from '@src/utils/format'
import { RevolutTheme, useTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'
import { ScoreGrade, scoreToColor, scoreToGrade } from '../../helpers'

const scoreToBgColor = (theme: RevolutTheme, score: number) => {
  switch (scoreToGrade(score)) {
    case ScoreGrade.LOW:
      return getColor(theme, Color.RED_10)
    case ScoreGrade.MID:
      return getColor(theme, Color.YELLOW_10)
    case ScoreGrade.HIGH:
      return getColor(theme, Color.GREEN_10)
    default:
      return getColor(theme, Color.FOREGROUND)
  }
}

const scoreToAvatar = (theme: RevolutTheme, score: number | null) => {
  const isOpenEnded = score === null

  if (isOpenEnded) {
    return <Avatar useIcon="Paragraph" />
  }
  return (
    <Avatar bg={scoreToBgColor(theme, score)}>
      <Circle size={24} variant="outlined" color={scoreToColor(theme, score)}>
        {score}
      </Circle>
    </Avatar>
  )
}

type Props = {
  comment: EngagementResultsComment
}
export const CommentItem = ({ comment }: Props) => {
  const theme = useTheme()

  return (
    <Cell py="s-12" px="s-16">
      <VStack space="s-12">
        <Item p={0}>
          <Item.Avatar>{scoreToAvatar(theme, comment.answer_score)}</Item.Avatar>
          <Item.Content>
            <Item.Title>Q: {comment.question.question_text}</Item.Title>
            <Item.Description>
              <VStack space="s-4">
                <Box>
                  {chain(
                    <Text fontSize="primary" color={Color.FOREGROUND}>
                      A: {comment.answer_text}
                    </Text>,
                    formatDateTime(comment.creation_date_time),
                  )}
                </Box>
                {!!comment.categories?.names?.length && (
                  <HStack space="s-4">
                    {comment.categories.names.map(name => (
                      <Tag
                        key={name}
                        variant="faded"
                        bg={Color.BLUE_20}
                        color={Color.FOREGROUND}
                      >
                        {name}
                      </Tag>
                    ))}
                  </HStack>
                )}
              </VStack>
            </Item.Description>
          </Item.Content>
        </Item>
      </VStack>
    </Cell>
  )
}
